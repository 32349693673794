import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, getSrc } from "gatsby-plugin-image"

import LayoutNew from "../components/layout-new"
import HeroSection from "../components/common/hero-section"
import SectionProductGuide from "../components/sections/section-product-guide"
import Seo from "../components/seo"

const PageProductGuide = () => {
  const data = useStaticQuery(graphql`
    query PageProductGuideQuery {
      imgHero: file(relativePath: { eq: "backgrounds/product-guide-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            placeholder: TRACED_SVG
          )
        }
      }
    }
  `)

  const imgHero = getImage(data.imgHero)
  const imgSrc = getSrc(data.imgHero)

  return (
    <LayoutNew>
      <Seo
        title="Product Guide"
        description="You can learn more about our 831(b) Plan and Safe Harbor Plans in our Product Guide."
        image={imgSrc}
        noIndex
      />
      <HeroSection
        title="Product Guide"
        imgSrc={imgHero}
      />
      <SectionProductGuide />
    </LayoutNew>
  )
}

export default PageProductGuide
